import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link, useParams } from 'react-router-dom';
import clsx from 'clsx';
import dots from '../../../images/icons/dots.svg';
import WatchNowMenu from './WatchNowMenu';

const DekstopMenu = ({
                       congressSlug,
                       hasContests,
                       hasWorkshops,
                       hasCurrentWorkshops,
                       hasCurrentAppointments,
                       currentAppointment,
                       watchNow,
                       currentWatchNowTab,
                       setCurrentWatchNowTab,
                       hasProducts,
                     }) => {
  const {sectionSlug} = useParams();
  const [showWatchNowMenu, setShowWatchNowMenu] = useState(false)
  const urlPrefix = `/${congressSlug}/${sectionSlug}`;
  const watchNowMenuProps = {
    congressSlug,
    setShowWatchNowMenu,
    hasCurrentWorkshops,
    hasCurrentAppointments,
    currentWatchNowTab,
    currentAppointment,
    setCurrentWatchNowTab,
    sectionSlug,
  }
  const isCurrentPage = page => window.location.pathname === `${urlPrefix}/${page}` && 'active'

  return (
    <nav className="desktop-navigation navigation-wrapper navbar-expand-md">
      <ul id="navbarNav"
          className={`navigation list-unstyled navbar-nav`}>
        {watchNow &&
        <li className="navigation--item watch-now d-md-inline-block">
          <img src={dots} alt="*" className="mr-2 mb-1 text-orange-500"/>

          <Link className={clsx('navigation-link', isCurrentPage('appointment'))}
                to="#" onClick={() => setShowWatchNowMenu(!showWatchNowMenu)} data-kongres={congressSlug}>Oglądaj</Link>
          {showWatchNowMenu && <WatchNowMenu {...watchNowMenuProps}/>}
        </li>}
        <li className="navigation--item">
          <Link className={clsx('navigation-link', isCurrentPage('sponsors'))} to={`${urlPrefix}/sponsors`} data-kongres={congressSlug}>Wystawcy</Link>
        </li>
        <li className="navigation--item">
          <Link className={clsx('navigation-link', isCurrentPage('agenda'))} to={`${urlPrefix}/agenda`} data-kongres={congressSlug}>Program</Link>
        </li>
        {(hasWorkshops && <li className="navigation--item">
            <Link className={clsx('navigation-link', isCurrentPage('workshops'))} to={`${urlPrefix}/workshops`} data-kongres={congressSlug}>Warsztaty</Link>
          </li>
        )}
        {(hasContests && <li className="navigation--item">
            <Link className={clsx('navigation-link', isCurrentPage('contests'))} to={`${urlPrefix}/contests`} data-kongres={congressSlug}>Konkursy</Link>
          </li>
        )}
        {(hasProducts && <li className="navigation--item">
          <Link className={clsx('navigation-link', isCurrentPage('marketplace'))} to={`${urlPrefix}/marketplace`} data-kongres={congressSlug}>Zakupy</Link>
        </li>
        )}
        <li className="navigation--item">
          <Link className={clsx('navigation-link', isCurrentPage('contact'))} to={`${urlPrefix}/contact`} data-kongres={congressSlug}>Kontakt</Link>
        </li>
        <li className="navigation--item">
          <a className="navigation-link" rel="nofollow" data-method="delete" href={`/${congressSlug}/sign_out`} data-kongres={congressSlug}>Wyloguj</a>
        </li>
      </ul>
    </nav>
  );
}

DekstopMenu.propTypes = {
  congressSlug: PropTypes.string,
};

export default DekstopMenu;
